<template>
  <div class="layout-root">
    <div
      v-if="theme === 'edition'"
      class="background"
      @click="navigateToParentPage"
    />

    <TransitionGroup
      name="list"
      tag="div"
      class="container"
    >
      <div
        key="main"
        class="main"
      >
        <div class="main__actions">
          <ReflectScopeButton
            v-if="selectedLegalReportRealizedPeriod"
            icon="icon-date-range"
            :label="selectedLegalReportRealizedPeriod"
          />
          <ReflectScopeButton
            :label="
              selectedLegalReportFilterFormattedValue ||
              $t(`sharedAnalytics.scope.toggle.COMPANY`)
            "
          />
          <div id="header-action-button" />
        </div>

        <div class="main__page">
          <slot v-if="availableLegalReports" />
          <Loader v-else />
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia'
import type { SlotsType } from 'vue'
import { useCubeStore } from '~/stores/cube'
import { useLegalReportsStore } from '~/stores/legalReports'
import { useLayoutStore } from '~/stores/layout'
import { getFormattedFilterValues } from '~/services/filters'
import {
  getLegalReportRealizedPeriod,
  getMainLegalReportFilter
} from '~/services/legalReports/main'
import { QUERY_PARAMETERS } from '~/types/queryParameters'
import { ROUTE } from '~/constants/routes'
import { formatDate2 } from '~/services/date'

export default {
  name: 'LegalReportsLayout',
  slots: Object as SlotsType<{
    default: {}
  }>,
  computed: {
    ...mapState(useLayoutStore, ['theme']),
    ...mapState(useLegalReportsStore, ['availableLegalReports', 'legalReport']),
    selectedLegalReportFilterFormattedValue() {
      return this.legalReport
        ? getFormattedFilterValues(
            getMainLegalReportFilter(this.legalReport.legalReportFilters)
          )
        : null
    },
    selectedLegalReportRealizedPeriod() {
      if (!this.legalReport) {
        return null
      }

      const [startDate, endDate] = getLegalReportRealizedPeriod(
        this.legalReport
      )
      return `${formatDate2(startDate)} - ${formatDate2(endDate)}`
    }
  },
  async created() {
    const cubeStore = useCubeStore()
    await cubeStore.loadMeta()
    await useLegalReportsStore().getAvailableLegalReports()
  },
  methods: {
    async navigateToParentPage() {
      const route = useRoute()
      const legalReportId = route.params.legalReport as string | undefined
      const legalReportSectionId = route.query[
        QUERY_PARAMETERS.LEGAL_REPORT_SECTION_ID
      ] as string | undefined

      if (!legalReportId || !legalReportSectionId) {
        return await navigateTo(ROUTE.LEGAL_REPORTS)
      }
      return await navigateTo({
        path: `${ROUTE.LEGAL_REPORTS}/${legalReportId}`,
        query: {
          [QUERY_PARAMETERS.LEGAL_REPORT_SECTION_ID]: legalReportSectionId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: auto;
}

.background {
  z-index: 2;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba($bg-tertiary, 0.6);
  cursor: pointer;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: $page-width;
}

.main {
  @include full-viewport-height();
  position: relative;
  padding: $margin-regular 40px $margin-medium;
  width: 100%;
  display: flex;
  box-sizing: border-box;

  &__actions {
    z-index: 1;
    position: absolute;
    right: 40px;
    height: 54px;
    display: flex;
    align-items: center;
    gap: $margin-small;
  }

  &__page {
    margin-left: $side-width;
    width: calc(100% - #{$side-width});
    position: relative;
  }
}
</style>
